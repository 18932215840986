<template>
  <Layout>
    <PageHeader :title="$t('setup.userdetails')" />
    <!-- Modals -->
    <!-- Delete Modal -->
    <modal
      v-if="deleteUserModal"
      @close="deleteUserModal = false"
      class="bigger-modal"
    >
      <div slot="title">{{ $t("setup.userdelete") }}</div>
      <div slot="body">
        <div>{{ $t("setup.undone") }}</div>
      </div>
      <div slot="footer">
        <button
          type="button"
          class="btn btn-md btn-primary waves-effect waves-light pr-4 pl-4 mr-3"
          @click="deleteUserModal = false"
        >
          {{ $t("setup.cancel") }}
        </button>
        <button
          type="button"
          class="btn btn-md btn-danger waves-effect waves-light pr-4 pl-4"
          @click="deleteUser"
        >
          {{ $t("setup.delete") }}
        </button>
      </div>
    </modal>
    <!-- Delete Modal END -->

    <!-- Change Password Modal -->
    <modal
      v-if="changePasswordModal"
      @close="changePasswordModal = false"
      class="bigger-modal"
    >
      <div slot="title">{{ $t("setup.changepassword") }}</div>
      <div slot="body">
        <div>
          <div class="form-group mt-2">
            <label>{{ $t("setup.pass") }}</label>
            <input
              type="password"
              class="form-control"
              :placeholder="$t('setup.pass')"
              v-model="newPassword"
              autocomplete="new-password"
              @blur="$v.newPassword.$touch()"
              :class="{
                'is-invalid': $v.newPassword.$error,
              }"
            />
            <div class="invalid-feedback">{{ $t("setup.required") }}</div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <button
          type="button"
          class="btn btn-md btn-danger waves-effect waves-light pr-4 pl-4 mr-3"
          @click="changePasswordModal = false"
        >
          {{ $t("setup.cancel") }}
        </button>
        <button
          type="button"
          class="btn btn-md btn-primary waves-effect waves-light pr-4 pl-4"
          @click="changePassword"
        >
          {{ $t("setup.changepassword") }}
        </button>
      </div>
    </modal>
    <!-- Change Password Modal END -->

    <!-- Assign Firm Modal -->
    <modal
      v-if="assignFirmModal"
      @close="assignFirmModal = false"
      class="bigger-modal"
    >
      <div slot="title">{{ $t("setup.assignfirm") }}</div>
      <div slot="body">
        <div>
          <v-select
            v-model="newFirm"
            :options="firms"
            label="label"
            :placeholder="$t('setup.assignfirm')"
          ></v-select>
        </div>
      </div>
      <div slot="footer">
        <button
          type="button"
          class="btn btn-md btn-danger waves-effect waves-light pr-4 pl-4 mr-3"
          @click="assignFirmModal = false"
        >
          {{ $t("setup.cancel") }}
        </button>
        <button
          type="button"
          class="btn btn-md btn-primary waves-effect waves-light pr-4 pl-4"
          @click="assignFirm"
        >
          {{ $t("setup.assignfirm") }}
        </button>
      </div>
    </modal>
    <!-- Assign Firm Modal END -->

    <!-- Assign Role Modal -->
    <modal
      v-if="assignRoleModal"
      @close="assignRoleModal = false"
      class="bigger-modal"
    >
      <div slot="title">{{ $t("setup.assignrole") }}</div>
      <div slot="body">
        <div>
          <v-select
            v-model="selectedRole"
            :options="roles"
            label="label"
            :placeholder="$t('setup.assignrole')"
          ></v-select>
        </div>
      </div>
      <div slot="footer">
        <button
          type="button"
          class="btn btn-md btn-danger waves-effect waves-light pr-4 pl-4 mr-3"
          @click="assignRoleModal = false"
        >
          {{ $t("setup.cancel") }}
        </button>
        <button
          type="button"
          class="btn btn-md btn-primary waves-effect waves-light pr-4 pl-4"
          @click="assignRole"
        >
          {{ $t("setup.assignrole") }}
        </button>
      </div>
    </modal>
    <!-- Assign Role Modal END -->

    <!-- Modals END -->
    <div class="row user-detail">
      <div class="col-12 col-sm-7">
        <div class="card" :class="{ 'edit-mode-active': updateMode }">
          <div class="row head-manage">
            <div class="col-6">
              <a
                @click="$router.go(-1)"
                class="btn btn-light btn-rounded waves-effect ml-3 mt-3 mb-3"
              >
                <i class="fas fa-angle-left"></i>
                {{ $t("setup.goback") }}
              </a>
            </div>
            <div class="col-6 text-right">
              <button
                type="button"
                class="
                  btn btn-md btn-danger
                  waves-effect waves-light
                  ml-3
                  mt-3
                  mb-3
                "
                v-if="updateMode"
                @click="updateMode = false"
              >
                {{ $t("setup.cancel") }}
              </button>
              <button
                type="button"
                class="
                  btn btn-md btn-primary
                  waves-effect waves-light
                  ml-3
                  mt-3
                  mb-3
                  mr-3
                "
                v-if="updateMode"
                @click="updateUser"
              >
                {{ $t("setup.update") }}
              </button>
              <button
                type="button"
                class="
                  btn btn-light btn-rounded
                  waves-effect
                  ml-3
                  mt-3
                  mb-3
                  mr-3
                "
                v-if="!updateMode"
                @click="updateMode = true"
              >
                <i class="far fa-edit"></i>
                {{ $t("setup.edituser") }}
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-3">
              <div class="card-body">
                <div class="met-profile">
                  <div class="met-profile-main">
                    <div class="met-profile-main-pic">
                      <file-upload
                        ref="upload"
                        class="file profile-img"
                        v-model="image"
                        accept="image/png, image/gif, image/jpeg, image/jpg"
                        extensions="gif,jpg,jpeg,png"
                        :disabled="!updateMode"
                      >
                      
                        <img
                          :src="
                            newUser.profileImg
                              ? $filesPath + newUser.profileImg
                              : require('@/assets/images/no-image.jpg')
                          "
                          v-if="!newProfileImageTemp"
                          alt="Profile Image"
                          class="rounded-circle c-size-prof"
                        />
                        <img
                          :src="newProfileImageTemp"
                          v-else
                          alt="Profile Image"
                          class="rounded-circle c-size-prof"
                        />
                        <span class="fro-profile_main-pic-change">
                          <i class="fas fa-camera"></i>
                        </span>
                      </file-upload>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group mt-2">
                      <label>{{ $t("setup.personelvideo") }}</label>
                      <div
                        class="embed-responsive embed-responsive-16by9 mb-2"
                        v-if="newUser.video_url"
                      >
                        <button
                          class="btn btn-danger btn-round btn-remove"
                          v-if="updateMode"
                        >
                          <i
                            class="fas fa-trash-alt"
                            @click="newUser.video_url = null"
                          ></i>
                        </button>
                        <video
                          width="320"
                          height="240"
                          controls
                          v-if="newUser.video_url"
                        >
                          <source
                            :src="$filesPath + newUser.video_url"
                            :type="setVideoTypeForVideoTag(newUser.video_url)"
                          />
                          {{ $t("profile.videoerr") }}
                        </video>
                      </div>

                      <div class="custom-file">
                        <input
                          type="file"
                          class="custom-file-input"
                          id="customFile"
                          accept="video/mp4,video/x-m4v,video/*"
                          :disabled="!updateMode"
                          @change="personelVideoFileChange"
                        />
                        <label class="custom-file-label" for="customFile">
                          {{
                            personelVideo ? personelVideo.name : "Choose file"
                          }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row meta-info">
                  <div class="col-12">
                    <i
                      class="fas fa-check-circle color-green"
                      v-if="newUser.isVerified"
                    ></i>
                    <i
                      class="fas fa-circle-notch"
                      v-if="!newUser.isVerified"
                    ></i>
                    <span
                      class="badge color-black"
                      v-if="newUser.isVerified == true"
                      >{{ $t("setup.verified") }}</span
                    >
                    <span
                      class="badge color-black"
                      v-if="newUser.isVerified == false"
                      >{{ $t("setup.notyetverified") }}</span
                    >
                  </div>
                  <div class="col-12 fs-1 mt-2" v-if="newUser.roleId">
                    <span
                      class="badge"
                      :class="[
                        { 'bg-primary': newUser.roleId == 1 },
                        { 'bg-warning': newUser.roleId == 2 },
                        { 'bg-info': newUser.roleId == 3 },
                      ]"
                    >
                      {{ $getRoleName(newUser.roleId) }}
                    </span>
                  </div>
                  <div class="col-12 fs-1 mt-3" v-if="newUser.createdAt">
                    <strong>{{ $t("setup.createddate") }}:</strong>
                    <p>
                      {{ new Date(newUser.createdAt).toLocaleString("tr") }}
                    </p>
                  </div>
                  <div class="col-12 fs-1 mt-2" v-if="newUser.firmId">
                    <strong>{{ $t("setup.assignfirm") }}:</strong>
                    <p>
                      {{ newUser.firm.name }}
                    </p>
                  </div>
                  <div class="col-12">
                    <strong>Status</strong>
                    <div class="custom-switch switch-primary">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="newUser.status"
                        :cheched="newUser.status"
                        v-model="newUser.status"
                        @change="updateUserStatus"
                      />
                      <label for="newUser.status" class="custom-control-label">
                        {{ newUser.status ? "Active user" : "Inactive user" }}
                      </label>
                    </div>
                  </div>
                  <hr />
                  <div class="col-12 mt-3 bordered p-2" v-if="$authenticatedUser().role == '1'">
                    <div class="col">
                      <strong>Premium</strong>
                    </div>
                    <DatePicker
                      class="datepicker"
                      :disabled="!updateMode"
                      v-model="newUser.subscriptionEndDate"
                      :placeholder="$t('setup.selectdate')"
                    ></DatePicker>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-9">
              <div class="card-body">
                <div class="form-group mt-2">
                  <label>{{ $t("setup.name") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Name"
                    autocomplete="nope"
                    v-model="newUser.name"
                    :disabled="!updateMode"
                    @blur="$v.newUser.name.$touch()"
                    :class="{
                      'is-invalid': $v.newUser.name.$error,
                    }"
                  />
                  <div class="invalid-feedback">{{ $t("setup.required") }}</div>
                </div>
                <div class="form-group mt-2">
                  <label>{{ $t("setup.title") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('setup.title')"
                    autocomplete="nope"
                    :disabled="!updateMode"
                    v-model="newUser.title"
                  />
                </div>
                <div class="form-group mt-2" v-if="!newUser.firmId">
                  <label>{{ $t("setup.compname") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('setup.compname')"
                    autocomplete="nope"
                    :disabled="!updateMode"
                    v-model="newUser.firmName"
                  />
                </div>
                <div class="form-group mt-2">
                  <label>Email</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Email"
                    autocomplete="nope"
                    v-model="newUser.email"
                    :disabled="!updateMode"
                    @blur="$v.newUser.email.$touch()"
                    :class="{
                      'is-invalid': $v.newUser.email.$error,
                    }"
                  />
                  <div class="invalid-feedback">{{ $t("setup.required") }}</div>
                </div>
                <div class="form-group mt-2">
                  <label>Web</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="web"
                    autocomplete="nope"
                    :disabled="!updateMode"
                    v-model="newUser.web"
                  />
                </div>
                <div class="form-group mt-2">
                  <label>Tel</label>
                  <vue-phone-number-input
                    v-model="phone"
                    @update="setPhone"
                    @blur="$v.newUser.tel.$touch()"
                    :show-code-on-list="true"
                    :default-country-code="newUser.countryCode"
                    :disabled="!updateMode"
                    :class="{
                      'is-invalid': $v.newUser.tel.$error,
                    }"
                  />

                  <div class="invalid-feedback">Required</div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-6">
                    <label>{{ $t("profile.promotinolvideo") }}</label>
                    <div
                      class="embed-responsive embed-responsive-16by9"
                      v-if="newUser.promotionalVideo"
                    >
                      <button
                        class="btn btn-danger btn-round btn-remove"
                        v-if="updateMode"
                      >
                        <i
                          class="fas fa-trash-alt"
                          @click="newUser.promotionalVideo = null"
                        ></i>
                      </button>
                      <video
                        width="320"
                        height="240"
                        controls
                        v-if="!checkIfYoutubeLink(newUser.promotionalVideo)"
                      >
                        <source
                          :src="$filesPath + newUser.promotionalVideo"
                          :type="
                            setVideoTypeForVideoTag(newUser.promotionalVideo)
                          "
                        />
                        {{ $t("profile.videoerr") }}
                      </video>

                      <iframe
                        width="560"
                        height="315"
                        v-if="checkIfYoutubeLink(newUser.promotionalVideo)"
                        :src="returnYoutubeEmbedCode(newUser.promotionalVideo)"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <div class="video-manage mt-2">
                      <b-tabs content-class="p-3 text-muted">
                        <b-tab active class="border-0">
                          <template v-slot:title>
                            <span class="d-inline-block d-sm-none">
                              <i class="fas fa-home"></i>
                            </span>
                            <span class="d-none d-sm-inline-block">{{
                              $t("setup.videoupload")
                            }}</span>
                          </template>
                          <div class="form-group mt-2">
                            <div class="custom-file">
                              <input
                                type="file"
                                class="custom-file-input"
                                id="customFile"
                                accept="video/mp4,video/x-m4v,video/*"
                                :disabled="!updateMode"
                                @change="promotionalVideoFileChange"
                              />
                              <label class="custom-file-label" for="customFile">
                                {{
                                  promotionalVideo
                                    ? promotionalVideo.name
                                    : "Choose file"
                                }}
                              </label>
                            </div>
                          </div>
                        </b-tab>
                        <b-tab>
                          <template v-slot:title>
                            <span class="d-inline-block d-sm-none">
                              <i class="far fa-user"></i>
                            </span>
                            <span class="d-none d-sm-inline-block"
                              >Youtube Link</span
                            >
                          </template>
                          <div class="form-group mt-2">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Youtube Link"
                              autocomplete="nope"
                              v-model="youtubeLink"
                              :disabled="!updateMode"
                            />
                          </div>
                        </b-tab>
                      </b-tabs>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6">
                    <div class="form-group mt-2">
                      <label>{{ $t("setup.promotinolpdf") }}</label>
                      <div class="row" v-if="newUser.pdfButton.link">
                        <div class="col-10">
                          <a
                            :href="$filesPath + newUser.pdfButton.link"
                            target="_blank"
                            rel="nofollow"
                            class="
                              btn btn-outline-light
                              waves-effect
                              btn-block
                              mb-2
                              s-o
                            "
                            ><i class="fas fa-file-pdf"></i>
                            {{ $t("setup.pdfrev") }}
                          </a>
                        </div>
                        <div class="col-2">
                          <button
                            class="btn btn-danger remove-pdf-button"
                            v-if="updateMode"
                            @click="removePDFData"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </div>

                      <div class="custom-file">
                        <input
                          type="file"
                          class="custom-file-input"
                          id="customFile"
                          :disabled="!updateMode"
                          accept=".pdf"
                          @change="promotionalPDFFileChange"
                        />
                        <label class="custom-file-label" for="customFile">
                          {{
                            promotionalPDF ? promotionalPDF.name : "Choose file"
                          }}</label
                        >
                      </div>
                    </div>
                    <div class="form-group mt-2" v-if="newUser.pdfButton">
                      <label> {{ $t("setup.pdfbtn") }}</label>
                      <input
                        type="text"
                        class="form-control"
                        :placeholder="newUser.pdfButton.buttonText"
                        autocomplete="nope"
                        v-model="newUser.pdfButton.buttonText"
                        :disabled="!updateMode"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-sm-12">
                    <div class="form-group mt-2 highlights">
                      <label>{{ $t("profile.highlights") }}</label>
                      <div v-if="newUser.highlights.length == 0">
                        <i
                          class="
                            mdi mdi-information
                            text-primary
                            me-1
                            align-middle
                            font-size-16
                          "
                        ></i>
                        No info, you can add info using
                        {{ updateMode ? "below + button." : "edit mode." }}
                      </div>
                      <draggable
                        class="list-group"
                        tag="ul"
                        v-model="newUser.highlights"
                        v-bind="dragOptions"
                      >
                        <!-- draggable=".item" -->
                        <transition-group type="transition">
                          <li
                            class="list-group-item"
                            v-for="(highlight, index) in newUser.highlights"
                            :key="index"
                            :class="[
                              newHighLightImage(index) || !highlight.image
                                ? 'new-item'
                                : 'item',
                            ]"
                          >
                            <i
                              class="fa fa-align-justify handle"
                              aria-hidden="true"
                            ></i>
                            <img
                              :src="$filesPath + highlight.image"
                              v-if="highlight.image"
                              class="img-thumbnail ml-2"
                              alt=""
                            />

                            <div class="col-8 form-group d-inline-block mt-4">
                              <div
                                class="custom-file mb-2"
                                v-if="!highlight.image && updateMode"
                              >
                                <input
                                  type="file"
                                  class="custom-file-input"
                                  id="customFile"
                                  accept="image/png, image/jpeg, image/jpg"
                                @change="function(file){highlightFileChange(file, index);}"
                                />

                                <label
                                  class="custom-file-label"
                                  for="customFile"
                                  >{{
                                    highlight.file
                                      ? highlight.file.name
                                      : "Choose file"
                                  }}</label
                                >
                              </div>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Link"
                                accept="image/png, image/jpeg, image/jpg"
                                autocomplete="nope"
                                v-model="highlight.link"
                                :disabled="!updateMode"
                              />
                            </div>
                            <div
                              class="
                                custom-switch
                                switch-primary
                                form-switch
                                positioned-right
                              "
                              v-if="updateMode"
                            >
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                :id="'h_index' + index"
                                :cheched="highlight.isActive"
                                v-model="highlight.isActive"
                              />
                              <label
                                :id="'h_index' + index"
                                class="custom-control-label"
                                @click="
                                  highlight.isActive = !highlight.isActive
                                "
                              >
                              </label>
                            </div>
                            <i
                              class="fas fa-trash-alt me-2 positioned-right"
                              v-if="updateMode"
                              @click="removeItem('highlight', index)"
                            ></i>
                          </li>
                        </transition-group>
                      </draggable>
                      <div class="col-12 text-center" v-if="updateMode">
                        <button
                          class="btn btn-light btn-rounded add-new-info"
                          @click="addInfo('highlight')"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-sm-12">
                    <div class="form-group mt-2">
                      <label>{{ $t("setup.social") }}</label>
                      <div v-if="newUser.socialAccounts.length == 0">
                        <i
                          class="
                            mdi mdi-information
                            text-primary
                            me-1
                            align-middle
                            font-size-16
                          "
                        ></i>
                        No info, you can add info using
                        {{ updateMode ? "below + button." : "edit mode." }}
                      </div>
                      <draggable
                        class="list-group"
                        tag="ul"
                        v-model="newUser.socialAccounts"
                        v-bind="dragOptions"
                      >
                        <transition-group type="transition">
                          <li
                            class="list-group-item"
                            v-for="(
                              socialAccount, index
                            ) in newUser.socialAccounts"
                            :key="index"
                          >
                            <i
                              class="fa fa-align-justify handle"
                              aria-hidden="true"
                            ></i>

                            <div
                              class="
                                custom-switch
                                switch-primary
                                form-switch
                                positioned-right
                              "
                              v-if="updateMode"
                            >
                              <input
                                type="checkbox"
                                class="custom-control-input"
                                :id="'sa_index' + index"
                                :cheched="socialAccount.isActive"
                                v-model="socialAccount.isActive"
                              />
                              <label
                                :id="'sa_index' + index"
                                class="custom-control-label"
                                @click="
                                  socialAccount.isActive =
                                    !socialAccount.isActive
                                "
                              >
                              </label>
                            </div>
                            <i
                              class="fas fa-trash-alt me-2 positioned-right"
                              v-if="updateMode"
                              @click="removeItem('social', index)"
                            ></i>
                            <div class="row">
                              <div class="col-4">
                                <div class="form-group">
                                  <label>{{ $t("setup.type") }}</label>
                                  <v-select
                                    class="mt-1"
                                    v-model="socialAccount.type"
                                    :options="$socialMediaList"
                                    label="label"
                                    :placeholder="$t('setup.selectfirm')"
                                  ></v-select>
                                </div>
                              </div>
                              <div class="col-8">
                                <div class="form-group">
                                  <label>Link</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Name"
                                    autocomplete="nope"
                                    v-model="socialAccount.link"
                                    :disabled="!updateMode"
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                        </transition-group>
                      </draggable>
                      <div class="col-12 text-center" v-if="updateMode">
                        <button
                          class="btn btn-light btn-rounded add-new-info"
                          @click="addInfo('social')"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group mt-2">
                  <label>{{ $t("setup.additional") }}</label>
                  <div v-if="newUser.additionalInfos.length == 0">
                    <i
                      class="
                        mdi mdi-information
                        text-primary
                        me-1
                        align-middle
                        font-size-16
                      "
                    ></i>
                    No info, you can add info using
                    {{ updateMode ? "below + button." : "edit mode." }}
                  </div>
                  <draggable
                    class="list-group"
                    tag="ul"
                    v-model="newUser.additionalInfos"
                    v-bind="dragOptions"
                  >
                    <transition-group type="transition">
                      <li
                        class="list-group-item"
                        v-for="(info, index) in newUser.additionalInfos"
                        :key="index"
                      >
                        <i
                          class="fa fa-align-justify handle"
                          aria-hidden="true"
                        ></i>
                        <div
                          class="
                            custom-switch
                            switch-primary
                            form-switch
                            positioned-right
                          "
                          v-if="updateMode"
                        >
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            :id="'i_index' + index"
                            :cheched="info.isActive"
                            v-model="info.isActive"
                          />
                          <label
                            :id="'i_index' + index"
                            class="custom-control-label"
                            @click="info.isActive = !info.isActive"
                          >
                          </label>
                        </div>
                        <i
                          class="fas fa-trash-alt me-2 positioned-right"
                          v-if="updateMode"
                          @click="removeItem('additional', index)"
                        ></i>
                        <div class="row">
                          <div class="col-4">
                            <div class="form-group">
                              <label>{{ $t("setup.type") }}</label>
                              <v-select
                                class="mt-1"
                                v-model="info.type"
                                :options="additionalInfoTypes"
                                label="label"
                                placeholder="Please select firm"
                              ></v-select>
                            </div>
                          </div>
                          <div class="col-8">
                            <div class="form-group">
                              <label>{{ $t("setup.value") }}</label>
                              <input
                                type="text"
                                class="form-control"
                                :placeholder="$t('setup.value')"
                                autocomplete="nope"
                                v-model="info.value"
                                :disabled="!updateMode"
                              />
                            </div>
                          </div>
                        </div>
                      </li>
                    </transition-group>
                  </draggable>
                  <div class="col-12 text-center" v-if="updateMode">
                    <button
                      class="btn btn-light btn-rounded add-new-info"
                      @click="addInfo('additional')"
                    >
                      +
                    </button>
                  </div>
                </div>

                <div class="form-group mt-2">
                  <label>{{ $t("setup.adresses") }}</label>
                  <div v-if="newUser.addresses.length == 0">
                    <i
                      class="
                        mdi mdi-information
                        text-primary
                        me-1
                        align-middle
                        font-size-16
                      "
                    ></i>
                    No info, you can add info using
                    {{ updateMode ? "below + button." : "edit mode." }}
                  </div>
                  <draggable
                    class="list-group"
                    tag="ul"
                    v-model="newUser.addresses"
                    v-bind="dragOptions"
                  >
                    <transition-group type="transition">
                      <li
                        class="list-group-item"
                        v-for="(address, index) in newUser.addresses"
                        :key="index"
                      >
                        <i
                          class="fa fa-align-justify handle"
                          aria-hidden="true"
                        ></i>
                        <div
                          class="
                            custom-switch
                            switch-primary
                            form-switch
                            positioned-right
                          "
                          v-if="updateMode"
                        >
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            :id="'a_index' + index"
                            :cheched="address.isActive"
                            v-model="address.isActive"
                          />
                          <label
                            :id="'a_index' + index"
                            class="custom-control-label"
                            @click="address.isActive = !address.isActive"
                          >
                          </label>
                        </div>
                        <i
                          class="fas fa-trash-alt me-2 positioned-right"
                          v-if="updateMode"
                          @click="removeItem('address', index)"
                        ></i>
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <label>{{ $t("setup.title") }}</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Title"
                                autocomplete="nope"
                                v-model="address.title"
                                :disabled="!updateMode"
                              />
                            </div>
                            <div class="form-group">
                              <label>{{ $t("setup.adress") }}</label>
                              <textarea
                                class="form-control"
                                placeholder="Adress"
                                autocomplete="nope"
                                v-model="address.desc"
                                :disabled="!updateMode"
                              />
                            </div>
                          </div>
                        </div>
                      </li>
                    </transition-group>
                  </draggable>
                  <div class="col-12 text-center" v-if="updateMode">
                    <button
                      class="btn btn-light btn-rounded add-new-info"
                      @click="addInfo('address')"
                    >
                      +
                    </button>
                  </div>
                </div>
                <div class="form-group mt-2">
                  <label>{{ $t("setup.bankinfos") }}</label>
                  <div v-if="newUser.billingInfos.length == 0">
                    <i
                      class="
                        mdi mdi-information
                        text-primary
                        align-middle
                        font-size-16
                      "
                    ></i>
                    No info, you can add info using
                    {{ updateMode ? "below + button." : "edit mode." }}
                  </div>
                  <draggable
                    class="list-group"
                    tag="ul"
                    v-model="newUser.billingInfos"
                    v-bind="dragOptions"
                  >
                    <transition-group type="transition">
                      <li
                        class="list-group-item"
                        v-for="(bankInfo, index) in newUser.billingInfos"
                        :key="index"
                      >
                        <div
                          class="
                            custom-switch
                            switch-primary
                            form-switch
                            positioned-right
                          "
                          v-if="updateMode"
                        >
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            :id="'b_index' + index"
                            :cheched="bankInfo.isActive"
                            v-model="bankInfo.isActive"
                          />
                          <label
                            :id="'b_index' + index"
                            class="custom-control-label"
                            @click="bankInfo.isActive = !bankInfo.isActive"
                          >
                          </label>
                        </div>
                        <i
                          class="fa fa-align-justify handle"
                          aria-hidden="true"
                        ></i>
                        {{ bankInfo.title }}
                        <i
                          class="fas fa-trash-alt me-2 positioned-right"
                          v-if="updateMode"
                          @click="removeItem('bank', index)"
                        ></i>
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <label>{{ $t("setup.title") }}</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Title"
                                autocomplete="nope"
                                v-model="bankInfo.title"
                                :disabled="!updateMode"
                              />
                            </div>
                            <div class="form-group">
                              <label>{{ $t("profile.bank") }}</label>
                              <input
                                type="text"
                                class="form-control"
                                :placeholder="$t('setup.additional')"
                                autocomplete="nope"
                                v-model="bankInfo.bank"
                                :disabled="!updateMode"
                              />
                            </div>
                            <div class="form-group">
                              <label>{{ $t("setup.recipientname") }}</label>
                              <input
                                type="text"
                                class="form-control"
                                :placeholder="$t('setup.title')"
                                autocomplete="nope"
                                v-model="bankInfo.recipientName"
                                :disabled="!updateMode"
                              />
                            </div>
                            <div class="form-group">
                              <label>IBAN</label>
                              <input
                                type="text"
                                class="form-control"
                                :placeholder="$t('setup.title')"
                                autocomplete="nope"
                                v-model="bankInfo.iban"
                                :disabled="!updateMode"
                              />
                            </div>
                          </div>
                        </div>
                      </li>
                    </transition-group>
                  </draggable>
                  <div class="col-12 text-center" v-if="updateMode">
                    <button
                      class="btn btn-light btn-rounded add-new-info"
                      @click="addInfo('bank')"
                    >
                      +
                    </button>
                  </div>
                </div>

                <div class="float-right">
                  <button
                    type="button"
                    class="
                      btn btn-md btn-danger
                      waves-effect waves-light
                      mb-3
                      pr-4
                      pl-4
                      mr-3
                    "
                    v-if="updateMode"
                    @click="updateMode = false"
                  >
                    {{ $t("setup.cancel") }}
                  </button>
                  <button
                    type="button"
                    class="
                      btn btn-md btn-primary
                      waves-effect waves-light
                      mb-3
                      pr-4
                      pl-4
                    "
                    v-if="updateMode"
                    @click="updateUser"
                  >
                    {{ $t("setup.update") }}
                  </button>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-5">
               <!-- <div class="card">
          <div class="card-body text-center fw-bold">
            SMS CODE: {{ newUser.code }}
            </div>
            </div> -->
        <div class="card">
          <div class="card-body">
            <div class="button-items edit-user">
              <button
                type="button"
                class="btn btn-primary waves-effect waves-light"
                @click="changePasswordModal = true"
              >
                <i class="fas fa-lock ms-2"></i>
                {{ $t("setup.changepassword") }}
              </button>
              <button
                type="button"
                class="btn btn-success waves-effect waves-light"
                @click="assignRoleModal = true"
                v-if="$authenticatedUser().role == '1'"
              >
                <i class="fas fa-user-cog"></i> {{ $t("setup.changerole") }}
              </button>
              <button
                type="button"
                class="btn btn-warning waves-effect waves-light"
                @click="assignFirmModal = true"
                v-if="$authenticatedUser().role == '1'"
              >
                <i class="fas fa-briefcase me-2"></i>
                {{ $t("setup.assignfirm") }}
              </button>
              <button
                type="button"
                class="btn btn-danger waves-effect waves-light"
                v-if="$parseJWT().id != newUser.id"
                @click="deleteUserModal = true"
              >
                <i class="fas fa-trash-alt me-2"></i>
                {{ $t("setup.userdelete") }}
              </button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col">
                <span class="h6">{{ $t("setup.cardgraph") }}</span>
                <div class="row">
                  <div class="col">
                    <b>{{ $t("setup.totalviews") }}:</b> {{ totalViews() }} |
                    <b>{{ $t("setup.totalsave") }}</b>
                    {{ totalSaveds() }}
                  </div>
                </div>
              </div>
              <div class="col">
                <DatePicker
                  class="datepicker"
                  v-model="dates"
                  placeholder="Select Date"
                  range
                  :shortcuts="shortcuts"
                  :disabled-date="disabledDates"
                ></DatePicker>
              </div>
            </div>
            <bar-chart :chartData="chartData" :saved="saved"></bar-chart>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { HTTP } from "@/main-source";
import router from "@/router";
import { required, email } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import modal from "@/components/modal";
import draggable from "vuedraggable";
import BarChart from "@/components/charts/Bar";

export default {
  name: "NewInvestor",
  data() {
    return {
      cities: [],
      image: [],
      firms: [],
      roles: [],
      selectedCity: null,
      phone: null,
      shortlink: this.$route.params.shortlink,
      updateMode: false,
      newUser: {
        name: null,
        email: null,
        profileImg: null,
        tel: null,
        video_url: null,
        desc: null,
        shortlink: null,
        by: null,
        willBeDelete: null,
        isVerified: null,
        highlights: [],
        socialAccounts: [],
        additionalInfos: [],
        addresses: [],
        bilgginInfos: [],
        title: null,
      },
      additionalInfoTypes: [
        { id: 1, type: "Phone", label: "Phone" },
        { id: 2, type: "Email", label: "Email" },
        { id: 3, type: "Simple Link", label: "Simple Link" },
        { id: 4, type: "Fax", label: "Fax" },
      ],
      newPassword: null,
      newFirm: null,
      selectedRole: null,
      // Modals
      deleteUserModal: false,
      changePasswordModal: false,
      assignFirmModal: false,
      assignRoleModal: false,
      dragOptions: {
        animation: 100,
        disabled: true,
        ghostClass: "ghost",
      },
      newProfileImageTemp: null,
      // Files
      profileImage: null,
      personelVideo: null,
      promotionalVideo: null,
      promotionalPDF: null,
      newHighlights: [],
      youtubeLink: null,
      dates: null,
      chartData: null,
      saved: null,
      shortcuts: [
        {
          text: "Today",
          onClick: () => (this.dates = [new Date(), new Date()]),
        },
        {
          text: "This Week",
          onClick: () => {
            var curr = new Date(); // get current date
            var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
            var last = first + 6; // last day is the first day + 6
            var firstday = new Date(curr.setDate(first));
            var lastday = new Date(curr.setDate(last));
            this.dates = [firstday, lastday];
            return this.dates;
          },
        },
        {
          text: "This Month",
          onClick: () => {
            var curr_date = new Date();

            var first_day = new Date(
              curr_date.getFullYear(),
              curr_date.getMonth(),
              1
            );

            var last_day = new Date(
              curr_date.getFullYear(),
              curr_date.getMonth() + 1,
              0
            );
            var month_start_date = this.formatted_date(first_day);
            var month_end_date = this.formatted_date(last_day);
            this.dates = [new Date(month_start_date), new Date(month_end_date)];
            return this.dates;
          },
        },
      ],
    };
  },
  validations: {
    newUser: {
      name: { required },
      email: { required, email },
      tel: { required },
    },
    newPassword: { required },
  },
  components: {
    Layout,
    PageHeader,
    modal,
    draggable,
    BarChart,
  },
  created() {
    this.getData();
  },
  methods: {
    totalViews() {
      let total = 0;

      this.chartData.forEach((item) => {
        total += item.pageViewCount;
      });
      return total;
    },
    totalSaveds() {
      let total = 0;
      this.saved.forEach((item) => {
        total += item.pageViewCount;
      });
      return total;
    },
    checkIfYoutubeLink(link) {
      if (link.includes("youtube")) {
        return true;
      }
      return false;
    },
    returnYoutubeEmbedCode(link) {
      if (link.includes("youtube")) {
        return "https://www.youtube.com/embed/" + link.split("v=")[1];
      }
    },
    setVideoTypeForVideoTag(videoName) {
      let ext = videoName.split(".")[1];
      return "video/" + ext;
    },
    getData() {
      HTTP.get("/users/profile/" + this.shortlink)
        .then((result) => {
          this.newUser = result.data;
          this.phone = result.data.tel;
          this.setJSONDataV2(result);
          this.getChartData();
          // Get Roles and set values
          HTTP.get("roles").then((result) => {
            if (result.data) {
              this.roles = result.data.map((role) => {
                if (this.newUser.firmId == null && role.id == 2) {
                  return;
                } else {
                  return { label: role.name, id: role.id };
                }
              });
            }
          });
        })
        .catch(() => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: this.$t("setup.cancel"),
            showConfirmButton: false,
            timer: 1000,
          });
        });
      HTTP.get("firms").then((result) => {
        if (result.data.rows) {
          this.firms = result.data.rows.map((firm) => {
            return { label: firm.name, id: firm.id };
          });
        }
      });
    },
    removePDFData: function () {
      this.newUser.pdfButton = {
        buttonText: "Broşürümüzü İnceleyin",
        link: null,
      };
    },
    setJSONDataV2(result) {
      // Set stringfied json

      if (result.data.highlights) {
        this.newUser.highlights = JSON.parse(result.data.highlights);
      } else {
        this.newUser.highlights = [];
      }
      // Set PDF Data
      if (result.data.pdfButton) {
        this.newUser.pdfButton = JSON.parse(result.data.pdfButton);
      } else {
        this.newUser.pdfButton = {
          buttonText: "Broşürümüzü İnceleyin",
          link: null,
        };
      }
      // Set socialAccounts
      if (result.data.socialAccounts) {
        this.newUser.socialAccounts = JSON.parse(result.data.socialAccounts);
      } else {
        this.newUser.socialAccounts = [];
      }
      // Set Additional Infos
      if (result.data.additionalInfos) {
        this.newUser.additionalInfos = JSON.parse(result.data.additionalInfos);
      } else {
        this.newUser.additionalInfos = [];
      }
      // Set Address
      if (result.data.addresses) {
        this.newUser.addresses = JSON.parse(result.data.addresses);
      } else {
        this.newUser.addresses = [];
      }
      // Set banking info as a billing
      if (result.data.billingInfos) {
        this.newUser.billingInfos = JSON.parse(result.data.billingInfos);
      } else {
        this.newUser.billingInfos = [];
      }
    },
    setPhone(val) {
      if (val.isValid == true) {
        this.newUser.countryCode = val.countryCode;
        if (this.newUser.countryCode != "TR") {
          this.newUser.tel = val.formattedNumber;
        } else {
          this.newUser.tel = val.nationalNumber;
        }
      } else {
        this.newUser.countryCode = null;
        this.newUser.tel = null;
      }
    },
    passwordToggle: function () {
      this.passInputType == "password"
        ? (this.passInputType = "text")
        : (this.passInputType = "password");
    },
    addInfo(what) {
      switch (what) {
        case "highlight":
          this.newUser.highlights.push({
            image: null,
            link: null,
            file: null,
            isActive: true,
          });
          break;

        case "additional":
          this.newUser.additionalInfos.push({
            type: null,
            value: null,
            isActive: true,
          });
          break;
        case "address":
          this.newUser.addresses.push({
            title: null,
            desc: null,
            mapFrame: null,
            isActive: true,
          });
          break;
        case "bank":
          this.newUser.billingInfos.push({
            title: null,
            bank: null,
            recipientName: null,
            iban: null,
            isActive: true,
          });
          break;
        case "social":
          this.newUser.socialAccounts.push({
            type: null,
            link: null,
            isActive: true,
          });
          break;
        default:
          break;
      }
    },
    removeItem(which, index) {
      switch (which) {
        case "highlight":
          this.newUser.highlights.splice(index, 1);
          break;
        case "additional":
          this.newUser.additionalInfos.splice(index, 1);
          break;
        case "address":
          this.newUser.addresses.splice(index, 1);
          break;
        case "bank":
          this.newUser.billingInfos.splice(index, 1);
          break;
        case "social":
          this.newUser.socialAccounts.splice(index, 1);
          break;
        default:
          break;
      }
    },
    makeRandom(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    async checkFilesAndUpload() {
      /* eslint-disable */
      try {
        if (this.profileImage) {
          let formData = new FormData();
          formData.append("files", this.profileImage.file);
          await HTTP.post("/files", formData, {
            headers: { "Content-Type": "multipart/form-data;" },
          })
            .then((result) => {
              this.newUser.profileImg = result.data.data[0].filename;
            })
            .catch((error) => {
              Swal.fire({
                position: "center",
                icon: "error",
                title: this.$t("setup.cancel"),
                showConfirmButton: false,
                timer: 1000,
              });
            });
        }
        if (this.personelVideo) {
          let formData = new FormData();
          formData.append("files", this.personelVideo);
          await HTTP.post("/files", formData, {
            headers: { "Content-Type": "multipart/form-data;" },
          })
            .then((result) => {
              this.newUser.video_url = result.data.data[0].filename;
            })
            .catch((error) => {
              Swal.fire({
                position: "center",
                icon: "error",
                title: this.$t("setup.cancel"),
                showConfirmButton: false,
                timer: 1000,
              });
            });
        }

        // Set promotional Video URL
        if (this.promotionalVideo) {
          this.youtubeLink = null;
          let formData = new FormData();
          formData.append("files", this.promotionalVideo);
          await HTTP.post("/files", formData, {
            headers: { "Content-Type": "multipart/form-data;" },
          })
            .then((result) => {
              this.newUser.promotionalVideo = result.data.data[0].filename;
            })
            .catch((error) => {
              Swal.fire({
                position: "center",
                icon: "error",
                title: this.$t("setup.cancel"),
                showConfirmButton: false,
                timer: 1000,
              });
            });
        }

        if (this.promotionalPDF) {
          let formData = new FormData();
          formData.append("files", this.promotionalPDF);
          await HTTP.post("/files", formData, {
            headers: { "Content-Type": "multipart/form-data;" },
          })
            .then((result) => {
              this.newUser.pdfButton.link = result.data.data[0].filename;
            })
            .catch((error) => {
              Swal.fire({
                position: "center",
                icon: "error",
                title: this.$t("setup.gerr"),
                showConfirmButton: false,
                timer: 1000,
              });
            });
        }
        let newHighlights = this.newUser.highlights.filter(
          (highlight) => highlight.file != null
        );
        if (newHighlights.length > 0) {
          for (let i = 0; i < newHighlights.length; i++) {
            if (newHighlights[i].file) {
              let formData = new FormData();
              formData.append("files", newHighlights[i].file);
              await HTTP.post("/files", formData, {
                headers: { "Content-Type": "multipart/form-data;" },
              })
                .then((result) => {
                  let item = this.newUser.highlights.filter(
                    (highlight) => highlight.file == newHighlights[i].file
                  )[0];
                  item.image = result.data.data[0].filename;
                  delete item.file;
                })
                .catch((error) => {
                  Swal.fire({
                    position: "center",
                    icon: "error",
                    title: this.$t("setup.cancel"),
                    showConfirmButton: false,
                    timer: 1000,
                  });
                });
            }
          }
        }
      } catch (e) {
        return e;
      }
    },
    async updateUser() {
      const result = await this.checkFilesAndUpload();

      if (this.newUser.addresses) {
        this.newUser.addresses = this.newUser.addresses.filter((address) => {
          return (
            address.title != "" &&
            address.title != null &&
            address.desc != "" &&
            address.desc != null
          );
        });
        console.log(this.newUser.addresses);
      }

      HTTP.put("/users/" + this.shortlink, this.newUser)
        .then((result) => {
          console.log(
            "🚀 ~ file: new.vue ~ line 887 ~ HTTP.put ~ result",
            result
          );
          Swal.fire({
            position: "center",
            icon: "success",
            title: this.$t("setup.userupdate"),
            showConfirmButton: false,
            timer: 1000,
          });
          this.updateMode = false;
        })
        .catch((e) => {
          console.log(
            "🚀 ~ file: new.vue ~ line 887 ~ HTTP.put ~ result",
            e.response.status
          );
          if (e.response.status == 409) {
            Swal.fire({
              position: "center",
              icon: "error",
              title: this.$t("setup.alreadyerr"),
              showConfirmButton: true,
            });
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: this.$t("setup.gerr"),
              showConfirmButton: false,
              timer: 2500,
            });
          }
        });
    },

    deleteUser() {
      HTTP.delete("users/" + this.shortlink)
        .then(() => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: this.$t("setup.userdeleted"),
            showConfirmButton: false,
            timer: 1000,
          });
          setTimeout(function () {
            router.push({ name: "users" });
          }, 1000);
        })
        .catch(() => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: this.$t("setup.gerr"),
            showConfirmButton: false,
            timer: 1000,
          });
        });
    },
    assignFirm() {
      let data = {
        firmId: this.newFirm ? this.newFirm.id : null,
      };

      HTTP.patch("users/" + this.shortlink + "/assignfirm", data)
        .then(() => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: this.$t("setup.userassigned"),
            showConfirmButton: false,
            timer: 1000,
          });
          this.assignFirmModal = false;
          this.newFirm = null;
          this.getData();
        })
        .catch(() => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: this.$t("setup.gerr"),
            showConfirmButton: false,
            timer: 1000,
          });
        });
    },
    assignRole() {
      if (this.selectedRole) {
        let data = {
          roleId: this.selectedRole.id,
        };

        HTTP.patch("users/" + this.shortlink + "/assignrole", data)
          .then(() => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: this.$t("setup.userassigned"),
              showConfirmButton: false,
              timer: 1000,
            });
            this.assignRoleModal = false;
            this.newFirm = null;
            this.getData();
          })
          .catch(() => {
            Swal.fire({
              position: "center",
              icon: "error",
              title: this.$t("setup.gerr"),
              showConfirmButton: false,
              timer: 1000,
            });
          });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("setup.selectarole"),
          showConfirmButton: false,
          timer: 1000,
        });
      }
    },
    changePassword() {
      this.$v.newPassword.$touch();
      if (this.$v.newPassword.$invalid) {
        return;
      } else {
        let data = {
          userId: this.newUser.id,
          password: this.newPassword,
        };

        HTTP.patch("users/changepassword", data)
          .then(() => {
            this.changePasswordModal = false;
            this.$v.$reset();
            Swal.fire({
              icon: "success",
              text: this.$t("setup.userupdate"),
            });
          })
          .catch(() => {
            Swal.fire({
              icon: "warning",
              text: this.$t("setup.checkform"),
            });
          });
      }
    },
    updateUserStatus() {
      let data = {
        status: this.newUser.status,
      };

      HTTP.patch("users/" + this.newUser.id + "/status", data).then(() => {});
    },
    // createImage(file) {
    //   var reader = new FileReader();

    //   reader.onload = (e) => {
    //     let isExists = this.images.filter(
    //       (image) => image.name == file.name
    //     ).length;
    //     if (isExists == 0) {
    //       this.newProfileImageTemp = e.target.result;
    //     }
    //   };
    //   reader.readAsDataURL(file);
    // },

    // FILES
    //  newProfileImageTemp: null,
    // personelVideo: null,
    // promotionalVideo: null,
    // promotionalPDF: null,
    personelVideoFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (files) {
        if (this.checkFileSize(files[0])) {
          this.personelVideo = files[0];
        }
      }
    },
    promotionalVideoFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (files) {
        if (this.checkFileSize(files[0])) {
          this.promotionalVideo = files[0];
          this.youtubeLink = null;
        }
      }
    },
    promotionalPDFFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (files) {
        if (this.checkFileSize(files[0])) {
          this.promotionalPDF = files[0];
        }
      }
    },
    highlightFileChange(e, index) {
      var files = e.target.files || e.dataTransfer.files;
      if (files) {
        if (this.checkFileSize(files[0])) {
          this.newUser.highlights[index].file = files[0];
          this.newUser.highlights[index].link = null;
        }
      }
    },
    newHighLightImage(index) {
      return this.newHighlights.filter(
        (highlight) => highlight.index == index
      )[0];
    },
    checkFileSize(file) {
      let maxSize = this.$maxFileSize;
      if (file.size > maxSize) {
        Swal.fire({
          icon: "warning",
          text: this.$t("setup.fileerr") + Math.floor(maxSize / 1000000) + "MB",
        });
        return false;
      }
      return true;
    },
    formatted_date(date) {
      var m = ("0" + (date.getMonth() + 1)).slice(-2); // in javascript month start from 0.
      var d = ("0" + date.getDate()).slice(-2); // add leading zero
      var y = date.getFullYear();
      return m + "-" + d + "-" + y;
    },
    getChartData() {
      let uri = "users/" + this.newUser.id + "/chart";

      if (this.dates) {
        if (this.dates[0]) {
          uri +=
            "?start=" +
            this.formatted_date(this.dates[0]) +
            "&finish=" +
            this.formatted_date(this.dates[1]);
        }
      }
      HTTP.get(uri).then((result) => {
        this.chartData = result.data.map((item) => {
          return {
            name: new Date(item.viewedAt).toLocaleDateString("tr"),
            pageViewCount: item.count,
          };
        });
      });
      uri = "users/" + this.newUser.id + "/chart/saved";
      if (this.dates) {
        if (this.dates[0]) {
          uri +=
            "?start=" +
            this.formatted_date(this.dates[0]) +
            "&finish=" +
            this.formatted_date(this.dates[1]);
        }
      }
      HTTP.get(uri).then((result) => {
        this.saved = result.data.map((item) => {
          return {
            name: new Date(item.viewedAt).toLocaleDateString("tr"),
            pageViewCount: item.count,
          };
        });
      });
    },
  },
  watch: {
    youtubeLink: function () {
      if (this.youtubeLink) {
        this.newUser.promotionalVideo = this.youtubeLink;
        this.promotionalVideo = null;
      }
    },
    image: function () {
      this.profileImage = this.image[0];
      if (this.checkFileSize(this.profileImage.file)) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.newProfileImageTemp = e.target.result;
        };
        reader.readAsDataURL(this.image[0].file);
      }
    },
    updateMode: function () {
      this.dragOptions.disabled = !this.updateMode;
    },
    changePasswordModal: function () {
      if (this.changePasswordModal) {
        this.newPassword = null;
        this.$v.newPassword.$reset();
      }
    },
    dates: function () {
      this.getChartData();
    },
  },
  computed: {
    disabledDates() {
      return this.$disabledDates;
    },
  },
};
</script>
